import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { ListItem } from '../CustomStyles/Typography';
import { GeneralCard } from '../General/GeneralCard';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4rem 0',
    marginBottom: '-20rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '-14rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-10rem',
    },
  },
  header: {
    paddingBottom: '1rem',
  },
  text: {
    textAlign: 'center',
  },
}));

export const WhyWorkWave = ({
  peWhyWorkwave: { header = '', subheader = '', blurbs = [] },
}) => {
  const classes = useStyles();
  const md = useMediaQuery('(max-width: 960px)');

  return (
    <Container className={classes.root}>
      <Grid container justifyContent='center'>
        <Typography variant='h2' className={classes.header} color='primary'>
          {header}
        </Typography>
        <Grid item xs={10}>
          <Typography variant='body1' className={classes.text}>
            {subheader}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' style={{ paddingTop: '4rem' }}>
        {blurbs.map((blurb, index) => (
          <GeneralCard card={blurb} key={index} />
        ))}
      </Grid>
    </Container>
  );
};
