import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, useMediaQuery, Typography } from '@material-ui/core';
import { ProductLogos } from '../../Home/ProductLogos';

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		marginBottom: '3rem',
// 	},
// }));

export const PrivateEquityLogos = ({
  privateEquityLogosArray,
  privateEquityLogosHeader,
}) => {
  const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width: 600px)');
  return (
    <div style={{ padding: sm ? '3rem 0' : md ? '0 3rem 5rem' : '0 5rem' }}>
      {!!privateEquityLogosHeader ? (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{ textAlign: 'center', padding: '2rem 0 0 0' }}
        >
          <Grid item xs={10} md={12} lg={8}>
            <Typography
              variant='h2'
              style={{
                fontWeight: 700,
                color: '#002D5C',
                // fontSize: '1.375rem',
              }}
            >
              {privateEquityLogosHeader}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <ProductLogos logos={privateEquityLogosArray} />
    </div>
  );
};
