import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    padding: '0px',
    borderRadius: '10px',
    minHeight: '231.27px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    width: '90%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: '500px',
      marginBottom: '2rem',
    },
    transition: 'transform 0.25s',
  },
  card: {
    width: '90%',
  },
  title: {
    textAlign: 'left',
    color: '#183B56',
    marginLeft: '1rem',
    lineHeight: '25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1rem',
    },
  },
  pos: {
    textAlign: 'left',
    lineHeight: '22px',
    fontFamily: ['Roboto', 'sans-serif'].join(),
  },
}));

export const GeneralCard = ({ card }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Card className={classes.root} elevation={0}>
        <CardContent
          style={{
            borderTop: `16px solid ${card.accentColor?.hexValue ?? '#002D5C'}`,
            minHeight: '210px',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
            alignItems: 'space-evenly',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <Grid item xs={2}>
              <FontAwesomeIcon
                icon={['fad', card.icon ?? 'fish']}
                style={{
                  color: card.accentColor?.hexValue ?? '#002D5C',
                  height: '60px',
                  width: '60px',
                  border: `1px solid #E5EAF4`,
                  borderRadius: '8px',
                  background: '#E5EAF4',
                  padding: '10px',
                  textAlign: 'center',
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant='h5' className={classes.title}>
                {card.header}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            style={{ marginTop: '16px' }}
          >
            <Typography variant='body1' className={classes.pos}>
              {card.body}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
