import React, { useState, useEffect } from 'react';

import { Grid, Container, Typography, makeStyles } from '@material-ui/core';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-material-ui-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F8FBFF',
    padding: '4rem 0',
  },
  rightSide: {
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 0',
    },
  },
  blurbText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

const LeftSide = ({ blurbs }) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} md={7} spacing={3} justifyContent='center'>
      {blurbs.map((blurb, index) => (
        <Grid item container alignItems='center' key={index}>
          <Grid item xs={2} sm={1}>
            <FontAwesomeIcon
              icon={['fad', blurb.icon ?? 'fish']}
              size='3x'
              style={{
                marginRight: '15px',
                background: '#e6eaf3',
                color: '#2A7ABC',
                padding: '5px',
                borderRadius: '5px',
                height: '42px',
                width: '42px',
              }}
            />
          </Grid>

          <Grid item xs={10} sm={11}>
            <Typography
              variant='h6'
              className={classes.blurbText}
              style={{ fontWeight: 500 }}
              color='primary'
            >
              {blurb.text}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const RightSide = ({ rightSideImages }) => {
  const [carouselOptions, setCarouselOptions] = useState({
    autoPlay: true,
    animation: 'slide',
    indicators: true,
    timeout: 500,
    interval: 7000,
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: true,
  });

  useEffect(() => {
    if (rightSideImages.length === 1) {
      setCarouselOptions({
        ...carouselOptions,
        navButtonsAlwaysInvisible: true,
        indicators: false,
      });
    } else {
      setCarouselOptions({
        ...carouselOptions,
        navButtonsAlwaysVisible: true,
      });
    }
  }, [rightSideImages]);
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      md={5}
      container
      justifyContent='center'
      className={classes.rightSide}
    >
      <Carousel
        style={{ height: '100%', width: '100%' }}
        autoPlay={carouselOptions.autoPlay}
        animation={carouselOptions.animation}
        indicators={carouselOptions.indicators}
        indicatorContainerProps={{
          style: {
            marginBottom: '16px',
            textAlign: 'left',
            marginTop: '-32px',
          },
        }}
        interval={carouselOptions.interval}
        timeout={carouselOptions.timeout}
        navButtonsAlwaysVisible={carouselOptions.navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={carouselOptions.navButtonsAlwaysInvisible}
        navButtonsProps={{
          style: {
            backgroundColor: '#989796',
            marginBottom: '5rem',
          },
        }}
      >
        {rightSideImages.map((image, index) => (
          <GatsbyImage image={image.asset.gatsbyImageData} key={index} />
        ))}
      </Carousel>
    </Grid>
  );
};

export const PrivateEquityCenterSection = ({
  privateEquityCenterSection: {
    _rawHeader = [],
    blurbs = [],
    rightSideImages = [],
  },
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Grid container justifyContent='center' alignItems='center'>
          <PortableText
            content={_rawHeader}
            serializers={{
              h2: ({ children }) => (
                <Typography
                  variant='h3'
                  component='h2'
                  color='primary'
                  style={{
                    fontWeight: 700,
                    padding: '2rem 0',
                    textAlign: 'center',
                  }}
                >
                  {children}
                </Typography>
              ),
              strong: ({ children }) => (
                <strong style={{ color: '#2A79BC' }}>{children}</strong>
              ),
            }}
          />
        </Grid>
        <Grid
          container
          justifyContent='space-evenly'
          alignItems='center'
          style={{ padding: '2rem 0' }}
        >
          <LeftSide blurbs={blurbs} />
          <RightSide rightSideImages={rightSideImages} />
        </Grid>
      </Container>
    </div>
  );
};
