import React, { useRef, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/PrivateEquity/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';

import FormModalContext from '../context/FormModalContext';
import { HeroModal } from '../components/Partners/Hero/HeroModal';
import { PrivateEquityLogos } from '../components/PrivateEquity/PrivateEquityLogos';
import { ProgramOffers } from '../components/PrivateEquity/ProgramOffers';
import { WhyWorkWave } from '../components/PrivateEquity/WhyWorkWave';
import { PrivateEquityCenterSection } from '../components/PrivateEquity/PrivateEquityCenterSection';
// import { Form } from '../components/ContactForm/Form';
// import { FeatureHeroModal } from '../components/General/FeatureHeroModal';

// const SingleTestimonial = loadable(() =>
// 	import('../components/Testimonials/SingleTestimonial')
// );
// const TestimonialCarousel = loadable(() =>
// 	import('../components/Testimonials/TestimonialCarousel')
// );
const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#5A7184',
    lineHeight: 1.8,
    '& a': {
      color: theme.workwaveBlue,
    },
  },
  // testimonialTitle: {
  // 	color: theme.workwaveBlue,
  // 	fontWeight: 700,
  // 	padding: '2rem 0',
  // },
  formPadding: {
    padding: '24rem 0 18rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '14rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6rem 0',
    },
  },
}));

const PrivateEquityPage = ({ data, location }) => {
  const privateEquity = data.pe.edges[0].node;
  const classes = useStyles();
  const formRef = useRef();
  // const waveRef = useRef();

  // const height = waveRef.current && waveRef.current.clientHeight;

  // useEffect(() => {
  // 	console.log(height);
  // }, [height]);

  //stuff for modal and form
  const { formModalOpen, handleModalClick, setFormModalOpen, email } =
    useContext(FormModalContext);

  const [heroModalOpen, setHeroModalOpen] = useState(false);

  //callback to prefill marketo forms
  // const callback = () => {
  // 	if (email) {
  // 		window.MktoForms2.onFormRender((form) => {
  // 			// console.log(form);
  // 			form.setValues({
  // 				Email: email,
  // 			});
  // 		});
  // 	}
  // };

  const {
    hero,
    metaDescription,
    metaTitle,
    introHeader,
    privateEquityLogos,
    privateEquityContactForm,
    privateEquityCenterSection,
    peWhyWorkwave,
  } = privateEquity;

  //checks user from team and swaps for team sugar form
  const params = new URLSearchParams(location.search);
  const source = params.get('source');

  const med = useMediaQuery('(max-width: 1024px)');
  const sm = useMediaQuery('(max-width: 600px)');
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Hero
        hero={hero}
        setHeroModalOpen={setHeroModalOpen}
        setFormModalOpen={setFormModalOpen}
      />
      <Container>
        <PrivateEquityLogos
          privateEquityLogosArray={privateEquityLogos}
          privateEquityLogosHeader={introHeader}
        />
      </Container>
      <PrivateEquityCenterSection
        privateEquityCenterSection={privateEquityCenterSection}
      />

      <WhyWorkWave peWhyWorkwave={peWhyWorkwave} />
      <WaveDownSVG height='213' width='100%' fill='#fff' />
      <div
        className={classes.formPadding}
        style={{
          backgroundImage: `url(${privateEquityContactForm.formBackground?.asset?.gatsbyImageData?.images.fallback.src})`,
          backgroundSize: 'cover',
          backgroundColor: '#002D5C',
          // marginBottom: `${height}px`,
        }}
      >
        <Form
          formId={privateEquityContactForm.marketoId}
          pardotUrl={
            source === 'team'
              ? 'https://documents.workwave.com/teamsoftware/sugar_forms/sugar_contact_us_global_form.html'
              : 'https://go.workwave.com/l/1042281/2024-01-24/65wgg1'
          }
          contactForm={privateEquityContactForm}
          privacy
          modal={false}
          location={location}
        />
      </div>
      <div>
        <WaveUpSVG width='100%' fill='#f4f8ff' />
      </div>
      <HeroModal
        open={heroModalOpen}
        setHeroModalOpen={setHeroModalOpen}
        wistiaLink={hero.wistiaLink}
      />
    </>
  );
};

export const query = graphql`
  query PrivateEquityPageQuery {
    pe: allSanityPrivateEquity {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            ctaText
            wistiaLink
            wistiaImage {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 520, width: 350)
              }
            }
            nameAndTitleImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawContent
            relatedIndustries {
              homeHeroPillTitle
              faIcon
              slug {
                current
              }
              accentColor {
                hexValue
              }
            }
          }
          introHeader
          privateEquityLogos {
            firmLogo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          privateEquityCenterSection {
            _rawHeader
            blurbs {
              icon
              text
            }
            rightSideImages {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 500)
              }
            }
          }
          peWhyWorkwave {
            header
            subheader
            blurbs {
              icon
              header
              body
            }
          }
          privateEquityContactForm {
            header
            formBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            marketoId
            pardotUrl
          }
        }
      }
    }
  }
`;
export default PrivateEquityPage;
